import * as React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Test from "./Components/Test"
import Declarations from "./Components/Declarations"
import Nav from "./Components/Nav";
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Login from './Components/pages/Login';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <div className="page-container">
        {/* <Nav /> */}
        <div className="page-content">
          <div className="main-wrapper">
            <Routes>
              <Route exact path='/' element={< Test />}></Route>
              <Route exact path='/login' element={< Login />}></Route>
              <Route exact path='/declarations' element={< Declarations />}></Route>
            </Routes>
          </div></div>
      </div>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
