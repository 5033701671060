import React, { Component } from 'react';

import axios from 'axios';
import Card from './Parts/Card';

// import AppBar from '@mui/material/AppBar';
// import Box from '@mui/material/Box';
// import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';

const api = axios.create({
    baseURL: "https://dechet.co-bm.com/prestataire-api/get-all-infractions",
});

export default class Declarations extends Component {

    state =  { isLoading: true, _declarations_table: [],error: false };

    constructor(props) {
        super(props);
        this.state =  { isLoading: true, _declarations_table: [],error: false }        
    }


     getAllContracts = () => {
        axios.get('https://dechet.co-bm.com/prestataire-api/get-all-infractions').then(res => { this.state._declarations_table = res.data  } )
      }


    componentDidMount(){
         this.getAllContracts()
         console.log(this.state._declarations_table)
    }   

    render() {
    
        return (
            <>
                {/* <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                News
                            </Typography>
                            <Button color="inherit">Login</Button>
                        </Toolbar>
                    </AppBar>
                </Box> */}

                <div className='row'>
                    <div className='col-sm-12 col-md-4'>
                        {/* {  this.state._declarations_table.map(_declarations => <Card title={_declarations.Type} />) } */}
                    </div>
                </div>
            </>
        )
    }
}


